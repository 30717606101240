import "./saga";
import { getDateInFormat } from "../app";
import password from "strong-password-generator";

export * from "./slice";

const DueDateMatch = {
  Critical: 15,
  High: 30,
  Medium: 45,
  Low: 90,
  Minimal: 180,
  Default: 45,
};

var defaultPasswordConfig = {
  base: "WORD",
  length: { min: 16, max: 16 },
  capsLetters: { min: 2, max: 3 },
  numerals: { min: 2, max: 3 },
  spacialCharactors: { includes: ["@", "_"], min: 2, max: 3 },
  spaces: { allow: false, min: 0, max: 0 },
};

const getTestState = (state) => state.continuity;

export const getBlueprints = (state) => {
  const _state = getTestState(state);
  return _state.blueprints;
};
export const getTests = (state) => {
  const _state = getTestState(state);
  return _state.tests;
};
export const getSeverityTypes = (state) => {
  const _state = getTestState(state);
  return _state.severityTypes;
};
export const getTestStatusTypes = (state) => {
  const _state = getTestState(state);
  return _state.statusList;
};
export const getProrityTypes = (state) => {
  const _state = getTestState(state);
  return _state.priorityTypes;
};
export const getDateRangeEnums = (state) => {
  const _state = getTestState(state);
  return _state.dateRangeEnumns;
};
export const getTestsTotalCount = (state) => {
  const _state = getTestState(state);
  return _state.totalTests;
};
export const getCurrentViewTest = (state) => {
  const _state = getTestState(state);
  return _state.currentTest;
};
export const getSeverityTypeById = (severities, severity, state) => {
  let list = Array.isArray(severities) ? severities : getSeverityTypes(state);
  return list.find(({ id }) => id === severity);
};
export const getStatusTypeById = (statusList, status, state) => {
  let list = Array.isArray(statusList) ? statusList : getTestStatusTypes(state);
  return list.find(
    ({ id }) => String(id).toLowerCase() === String(status).toLowerCase()
  );
};
export const getStatistics = (state) => {
  const _state = getTestState(state);
  return _state.statistics;
};
export const getDueDateByPriority = (priority) => {
  return DueDateMatch[priority];
};
export const getReportPassword = (org) => {
  const orgFirstLetter = String(org.legalname)[0].toUpperCase();
  const suffix = `${orgFirstLetter}${getDateInFormat(undefined, "YYMM")}RPR`;
  const randomPass = password.generatePassword(defaultPasswordConfig);
  return { suffix, pass: randomPass };
};
export const isTestResponder = (test, user) => {
  let index = -1;
  if (test && Array.isArray(test.continuer_responders)) {
    index = test.continuer_responders.findIndex(
      ({ ent_usr_id }) => user.id === ent_usr_id
    );
  }
  return index > -1;
};
export const isTestAdvisor = (test, user) => {
  if (test && test.reviewer_id) {
    return Number(test.reviewer_id) === Number(user.id);
  }
};
export const isTestCreator = (test, user) => {
  if (test && test.creator_id) {
    return Number(test.creator_id) === Number(user.id);
  }
  return false;
};
export const isTestInProgress = (test) => {
  return test && String(test.status).toLowerCase() === "inprogress";
};
export const isTestOpen = (test) => {
  return test && String(test.status).toLowerCase() === "open";
};
export const isTestInReview = (test) => {
  return test && String(test.status).toLowerCase() === "review";
};
export const isTestClosed = (test) => {
  return test && String(test.status).toLowerCase() === "closed";
};
export const isTestAbandoned = (test) => {
  return test && String(test.status).toLowerCase() === "abandoned";
};
export const isTestRedundant = (test) => {
  return test && String(test.status).toLowerCase() === "redundant";
};
export const getTestReport = (test) => {
  if (test) {
    return test.attachments.find(({ title }) => {
      return title === "saved_continuity_report";
    });
  }
  return null;
};

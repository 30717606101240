import { put, select, takeEvery } from "redux-saga/effects";
import {
  SagaRegistry,
  setAlertAndLoading,
  axios,
  requestHandler,
  getDateIn24Format,
  dateSorter,
  getUserDetails,
  getDateInFormat,
  getDateInFullYearIn24Format,
  fetchS3ObjectAsBlob,
} from "../app";
import { continuityActions } from "./slice";
import { getDepartments } from "../common";
import { saveAs } from "file-saver";

function* getTest(_, iOptions) {
  iOptions = iOptions || {};
  const downloadReport =
    iOptions.downloadReport !== undefined ? iOptions.downloadReport : true;
  const test = JSON.parse(JSON.stringify(_));
  test.due_date = getDateInFormat(test.due_date);
  test.created_at = getDateInFullYearIn24Format(test.created_at);
  getUserDetails(test, "creator");
  test.continuer_responders = (test.continuer_responders || []).map((_) => {
    getUserDetails(_, "responder");
    return _;
  });
  test.status =
    test.status.toLowerCase() === "inprogress" ? "Started" : test.status;
  if (Array.isArray(test.tasks)) {
    test.tasks.sort(dateSorter.bind(null, "created_at"));
    test.tasks = test.tasks.map((_) => {
      const assignee = _.assignee;
      let responsible = "-";
      if (assignee) {
        getUserDetails(assignee);
        responsible = assignee._listname;
      }
      return { ..._, "task-status": _.status, responsible: responsible };
    });
  }
  if (downloadReport) {
    for (let i = 0; i < test.attachments.length; i++) {
      if (
        test.attachments[i].title === "saved_continuity_report" &&
        test.status === "closed"
      ) {
        // incident.attachments[i].evidence_url_full = axios.getFullPath(`/educators/s3_download_file?url=${incident.attachments[i].evidence_url}`);
        test.attachments[i].blob = yield fetchS3ObjectAsBlob(
          test.attachments[i].encrypt_evidence_url
        );
        break;
      }
    }
  }
  if (Array.isArray(test.comments)) {
    let appendixID = 1;
    test.comments = test.comments.map((_) => {
      const comment = { ..._ };
      comment.attachments = comment.attachments.map((_) => {
        let attachment = { ..._ };
        attachment.appendixID = `${
          appendixID < 10 ? "00" : appendixID < 100 ? "0" : ""
        }${appendixID}`;
        appendixID++;
        return attachment;
      });
      getUserDetails(comment, "creator");
      comment.created_at = getDateInFullYearIn24Format(comment.created_at);
      return comment;
    });
  }
  return test;
}
function* autoUpdateStatus(test) {
  yield requestHandler(function* () {
    if (String(test.status).toLowerCase() === "open") {
      let payload = new FormData();
      payload.append("continuer[status]", "Started");
      payload.append("continuer_id", test.id);
      yield axios.put("/continuers/update_continuer", payload);
    }
  }, "Failed to update Test");
}
function* fetchActiveTestBluprints() {
  yield requestHandler(function* () {
    const res = yield axios.get(`continuer_blueprints/content_con_bp_list`);
    let blueprints = res.bp_list ? res.bp_list : res;
    blueprints = Array.isArray(blueprints) ? blueprints : [];
    yield put(continuityActions.setBlueprints(blueprints));
  }, "Failed to load Blueprints");
}
function* fetchTests({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload && payload.pageNo ? payload.pageNo : 1;
    const pageSize = payload && payload.pageSize ? payload.pageSize : 15;
    const search = payload && payload.search ? payload.search : {};
    const res = yield axios.post(
      `/continuers/continuer_list_search/?page=${pageNo}&per_page=${pageSize}`,
      search
    );
    let tests = res.continuer_list ? res.continuer_list : res;
    tests = Array.isArray(tests) ? tests : [];
    tests.sort(dateSorter.bind(null, "created_at"));
    let finalTests = [];
    for (let i = 0; i < tests.length; i++) {
      let test = tests[i];
      test = yield getTest(test, { downloadReport: false });
      finalTests.push(test);
    }
    const totalCount = res.total_count || finalTests.length;
    yield put(continuityActions.setTotalCount(totalCount));
    yield put(continuityActions.setTests(finalTests));
  }, "Failed to load Tests");
}
function* fetchTest({ payload }) {
  yield requestHandler(function* () {
    let { testId } = payload;
    if (Number.isNaN(Number(testId))) {
      testId = yield axios.get("/continuers/get_continuer_id?guid=" + testId);
      testId = testId.id;
    }
    let test = yield axios.get(
      "/continuers/show_continuer?continuer_id=" + testId
    );
    if (test && test.id) {
      test = yield getTest(test);
    } else {
      test = "NOTFOUND";
    }
    yield put(continuityActions.setCurrentTest({ test }));
  }, "Failed to load Test");
}
function* createTest({ payload }) {
  yield requestHandler(function* () {
    yield axios.post(`/continuers`, payload);
    yield put(continuityActions.fetchTests());
    yield setAlertAndLoading(null, { message: "Test Created Successfully." });
  }, "Failed to Create Test");
}
function* fetchStatusList({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get(`/continuers/status_list`);
    let list = res.status_list ? res.status_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    });
    yield put(continuityActions.setTestStatusList(list));
  }, "Failed to Create Test");
}
function* updateTestResponder({ payload }) {
  yield requestHandler(function* () {
    const { testId, responderId } = payload;
    const body = {
      responder: { ent_usr_id: responderId },
      continuer_id: testId,
    };
    let test = yield axios.put("/continuers/add_con_responder", body);
    yield autoUpdateStatus(test);
    test = yield getTest(test);
    yield put(continuityActions.setCurrentTest({ test, testId }));
    yield setAlertAndLoading(null, { message: "Test Record has been updated" });
  }, "Failed to Add Responder to Test");
}
function* addTestComment({ payload }) {
  yield requestHandler(function* () {
    let test = yield axios.post(
      "/continuers/comment",
      payload,
      axios.getFormDataContentType()
    );
    yield autoUpdateStatus(test);
    test = yield getTest(test);
    yield put(continuityActions.setCurrentTest({ test, testId: test.id }));
    yield setAlertAndLoading(null, { message: "Test Record has been updated" });
  }, "Failed to Add comment to Test");
}
function* createTestTask({ payload }) {
  yield requestHandler(function* () {
    let test = yield axios.post("/continuers/create_task", payload);
    yield autoUpdateStatus(test);
    test = yield getTest(test);
    yield put(continuityActions.setCurrentTest({ test, testId: test.id }));
    yield setAlertAndLoading(null, {
      message: "Test Task Created successfully",
    });
  }, "Failed to create task to Test");
}
function* updateTest({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    // if status is changing to redundant, then add comment
    const addComment = payload.get("addComment");
    const status = payload.get("continuer[status]");
    const justification = payload.get("continuer[justification]");
    if (addComment && justification && status) {
      const continuityId = payload.get("continuer_id");
      const comment = new FormData();
      comment.append(
        `comment[comment]`,
        `Marked as ${status}.<br/>Justification: ${justification}`
      );
      comment.append("continuer_id", continuityId);
      yield axios.post(
        "/continuers/comment",
        comment,
        axios.getFormDataContentType()
      );
    }
    let test = yield axios.put("/continuers/update_continuer", payload);
    yield autoUpdateStatus(test);
    test = yield getTest(test);
    yield put(continuityActions.setCurrentTest({ test, testId: test.id }));
    yield setAlertAndLoading(null, { message: "Test Updated successfully" });
  }, "Failed to update Test");
}
function* fetchDateRangeEnums() {
  yield requestHandler(function* () {
    // const res = yield axios.get(`/incidents/date_range_list`);
    let list = [
      { id: "last_7_days", label: "Last 7 Days" },
      { id: "last_week", label: "Last Week (Sun-Sat)" },
      { id: "last_30_days", label: "Last 30 Days" },
      { id: "last_month", label: "Previous Month" },
      { id: "last_90_days", label: "Last 90 Days" },
      { id: "previous_quarter", label: "Previous Quarter" },
      { id: "last_365_days", label: "Last 365 Days" },
      { id: "previous_year", label: "Previous Year (Apr-Mar)" },
      { id: "over_all", label: "Entire History" },
    ];
    yield put(continuityActions.setDateRangeEnums(list));
  }, "Failed to load Date Range list");
}
function* fetchDeptStats({ payload }) {
  yield requestHandler(function* () {
    const body = {
      date_range_type: payload.dateRangeType,
      start_date: payload.startDate,
      end_date: payload.endDate,
    };
    const { department_status } = yield axios.post(
      `/continuers/department_dashboard`,
      body
    );
    let statusTypes = yield select((state) => state.continuity.statusList);
    let extraStatusTypes = [
      { id: "Minimal", label: "Minimal" },
      { id: "Other", label: "Other" },
    ];
    let allStatusTypes = [...statusTypes, ...extraStatusTypes];
    const user = yield select((state) => state.user.currentUser);
    const departments = yield select(getDepartments);
    let deptStats = [],
      compStats = { overall: 0, label: user.ent_org.brandname };
    if (Array.isArray(departments)) {
      for (let i = 0; i < departments.length; i++) {
        const dept = departments[i];
        const deptStatus = department_status && department_status[dept.id];
        let row = {
          ...dept,
          overall: 0,
          minimal: 0,
          other: 0,
        };
        allStatusTypes.forEach(({ id }) => {
          let attribute = String(id).toLowerCase();
          let count =
            (deptStatus &&
              department_status[dept.id] &&
              department_status[dept.id].statuses &&
              department_status[dept.id].statuses[attribute]) ||
            0;
          row[attribute] = count;
          row.overall += count;
          if (!compStats[attribute]) {
            compStats[attribute] = 0;
          }
          compStats[attribute] += count;
        });
        compStats.overall += row.overall;
        row.other =
          row.overall - (row.open + row.closed + row.inprogress + row.minimal);
        deptStats.push(row);
      }
      deptStats.sort((a, b) =>
        a.id.toLowerCase().localeCompare(b.id.toLowerCase())
      );
      deptStats.unshift(compStats);
      deptStats = deptStats.filter((row) => {
        return Object.entries(row).some(
          ([key, value]) => key !== "label" && key !== "id" && value !== 0
        );
      });
      // Replace 0 values with '-'
      deptStats = deptStats.map((row) => {
        let updatedRow = { ...row };
        Object.keys(updatedRow).forEach((columnKey) => {
          if (updatedRow[columnKey] === 0 || updatedRow[columnKey] === "0") {
            updatedRow[columnKey] = "-";
          }
        });
        return updatedRow;
      });
    }
    yield put(continuityActions.setStatistics({ department: deptStats }));
  }, "Failed to load Statistics");
}

function* exportDeptStats({ payload }) {
  const { filename, dateRangeType } = payload;
  yield requestHandler(function* () {
    const body = { date_range_type: dateRangeType };
    const res = yield axios.postExport(
      `/continuers/department_dashboard_to_png`,
      body
    );
    if (res && res.error) {
      yield setAlertAndLoading(null, {
        message: `Failed to export : ${filename}`,
        success: false,
      });
    } else {
      saveAs(res, filename);
    }
  }, `Failed to export : ${filename}`);
}
function* downloadTestReport({ payload }) {
  yield requestHandler(function* () {
    const { testId, password, filename } = payload;
    try {
      const body = { continuer_id: testId, pwd: password };
      const res = yield fetch(
        axios.getFullPath(`/continuers/export_inc_report`),
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: axios.getHeaders().headers,
        }
      )
        .then((response) => {
          return response.status === 200 ? response.blob() : null;
        })
        .catch((e) => {
          return null;
        });
      if (res) {
        if (!filename.includes(".pdf")) {
          filename += ".pdf";
        }
        yield setAlertAndLoading(null, {
          message: "Test Report downloaded successfully",
        });
        saveAs(res, filename);
      } else {
        throw "Error";
      }
    } catch (error) {
      yield setAlertAndLoading(null, {
        success: false,
        message: "Test Report Download Failed",
      });
    }
  }, "Failed to download Test report");
}
function* shareTestReport({ payload }) {
  yield requestHandler(function* () {
    const { testId, password, emailIds } = payload;
    const body = { continuer_id: testId, pwd: password, email_ids: emailIds };
    yield axios.post("/continuers/share_inc_report", body);
    yield setAlertAndLoading(null, {
      message: "Test Report shared successfully",
    });
  }, "Failed to share Test report");
}
function* removeTestResponder({ payload }) {
  yield requestHandler(function* () {
    const { testId, responderId } = payload;
    const body = {
      responder: { ent_usr_id: responderId },
      continuer_id: testId,
    };
    let test = yield axios.put("/continuers/remove_con_responder", body);
    yield autoUpdateStatus(test);
    test = yield getTest(test);
    yield put(continuityActions.setCurrentTest({ test }));
    yield setAlertAndLoading(null, { message: "Test Record has been updated" });
  }, "Failed to remove Responder to Test");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("continuity/fetchDateRangeEnums", fetchDateRangeEnums);
  yield takeEvery("continuity/fetchTests", fetchTests);
  yield takeEvery("continuity/fetchStatusList", fetchStatusList);
  yield takeEvery("continuity/createTest", createTest);
  yield takeEvery("continuity/fetchTest", fetchTest);
  yield takeEvery(
    "continuity/fetchActiveTestBluprints",
    fetchActiveTestBluprints
  );
  yield takeEvery("continuity/updateTestResponder", updateTestResponder);
  yield takeEvery("continuity/removeTestResponder", removeTestResponder);
  yield takeEvery("continuity/addTestComment", addTestComment);
  yield takeEvery("continuity/createTestTask", createTestTask);
  yield takeEvery("continuity/updateTest", updateTest);
  yield takeEvery("continuity/downloadTestReport", downloadTestReport);
  yield takeEvery("continuity/shareTestReport", shareTestReport);
  yield takeEvery("continuity/fetchDeptStats", fetchDeptStats);
  yield takeEvery("continuity/exportDeptStats", exportDeptStats);
});
